<template>
  <view-item title="类型设置">
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-table :data="table.data" :loading="table.loading" :height="height" :method="table.get">
            <en-table-column label="服务类型" prop="categoryKey.message">
              <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityCategoryDto'] }">
                <en-button link type="primary" @click="table.operation.edit.click(row)">{{ row.categoryKey?.message }}</en-button>
              </template>
            </en-table-column>
            <en-table-column label="提前通知（天）" prop="days"></en-table-column>
            <en-table-column label="状态" prop="status.message"></en-table-column>
            <en-table-column label="微信提醒（天）" prop="wechatDays"></en-table-column>
            <en-table-column label="微信提醒状态" prop="wechatStatus.message"></en-table-column>
            <en-table-column label="更新时间" prop="modifiedDate"></en-table-column>
          </en-table>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <opportunity-category-detail v-model="detail.visible" :data="detail.data" @confirm="table.get"></opportunity-category-detail>
</template>

<script lang="ts">
import OpportunityCategoryDetail from '@client/components/opportunity-category-detail.vue'

export default factory({
  components: { OpportunityCategoryDetail },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/category',
            data: 'array',
            loading: true
          }
        },
        children: {
          operation: {
            edit: {
              click(row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityCategoryDto']) {
                this.detail.data = Object.assign({}, row)
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        data: {} as EnocloudBusinessOpportunityDefinitions['BusinessOpportunityCategoryDto']
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
